// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-js": () => import("./../src/pages/posts.js" /* webpackChunkName: "component---src-pages-posts-js" */),
  "component---src-pages-success-js": () => import("./../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-templates-page-page-js": () => import("./../src/templates/Page/Page.js" /* webpackChunkName: "component---src-templates-page-page-js" */),
  "component---src-templates-post-post-js": () => import("./../src/templates/Post/Post.js" /* webpackChunkName: "component---src-templates-post-post-js" */),
  "component---src-templates-service-service-js": () => import("./../src/templates/Service/Service.js" /* webpackChunkName: "component---src-templates-service-service-js" */)
}

